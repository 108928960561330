import React from "react";
import Chat from "./Chat.jsx";
import Player from "./Player.jsx";

function render(parent) {
  if (
    !parent.state.players ||
    !parent.state.playerSettings ||
    !parent.state.players[0]
  )
    return null;
  return (
    <React.Fragment>
      <div
        className={
          parent.state.players && parent.state.players.length === 2
            ? "container double-player"
            : "container"
        }
      >
        <div className="d-flex flex-column flex-md-row align-items-center px-3 pb-0 px-md-4">
          <div className="col-auto">
            <img
              src={parent.state.logo}
              alt="virtualset logo"
              className="logo"
            />
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <div className="logout" id="logout">
              <i className="fas fa-user"></i> {parent.state.userName}{" "}
              <i
                className="fas fa-sign-out-alt ml-4 pointer"
                onClick={() => {
                  parent.logout();
                }}
              ></i>
            </div>
          </div>
        </div>
        {populatePlayers(
          parent.state.players,
          parent.state.playerSettings,
          parent.state.liveId,
          parent.state.legacyMode
        )}
        {parent.state.hasChat ? (
          <Chat
            channel={parent.state.channel}
            chatExpire={parent.state.chatExpire}
            hasAudio={parent.state.hasAudio}
            hasChat={parent.state.hasChat}
            userId={parent.state.userId}
            userName={parent.state.userName}
            muted={parent.state.mode === "muted"}
            messages={parent.state.messages}
            sendMessage={(m) => {
              parent.sendMessage(m);
            }}
          />
        ) : null}

        <div className="row justify-content-center">
          <div className="col-auto">
            {parent.state.legacyMode ? (
              <p className="mb-3 mt-1 toggle-mode">
                Vuoi tornare alla visualizzazione standard?{" "}
                <strong
                  className="pointer"
                  onClick={(e) => {
                    parent.setState({
                      legacyMode: !parent.state.legacyMode,
                    });
                  }}
                >
                  clicca qui
                </strong>
              </p>
            ) : (
              <p className="mb-3  mt-1 toggle-mode">
                Hai problemi nel visualizzare il video?{" "}
                <strong
                  className="pointer"
                  onClick={(e) => {
                    parent.setState({
                      legacyMode: !parent.state.legacyMode,
                    });
                  }}
                >
                  clicca qui
                </strong>
              </p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function populatePlayers(players, settings, liveId, legacyMode) {
  if (!players || !settings) return null;
  const playerPari = players.length % 2 === 0;
  return (
    <div className="row">
      {players.map((player, key) => {
        const completeUrl = `${settings.baseUrl}${liveId}-${player.id}`;

        if (players.length == 1)
          return (
            <>
              <div className="col-md-1" key={key + 100}></div>
              <div className="col-md-10" key={key}>
                <Player
                  id={player.id}
                  name={player.name}
                  url={completeUrl}
                  index={key}
                  syncBuffer={settings.syncBuffer}
                  buffer={settings.buffer}
                  sync={settings.sync}
                  legacy={legacyMode}
                />
              </div>
              <div className="col-md-1" key={key + 200}></div>
            </>
          );

        if (!playerPari && key === 0)
          return (
            <>
              <div className="col-md-3" key={key + 100}></div>
              <div className="col-md-6" key={key}>
                <Player
                  id={player.id}
                  name={player.name}
                  url={completeUrl}
                  index={key}
                  syncBuffer={settings.syncBuffer}
                  buffer={settings.buffer}
                  sync={settings.sync}
                  legacy={legacyMode}
                />
              </div>
              <div className="col-md-3" key={key + 200}></div>
            </>
          );

        return (
          <div className="col-md-6" key={key}>
            <Player
              id={player.id}
              name={player.name}
              url={completeUrl}
              index={key}
              syncBuffer={settings.syncBuffer}
              buffer={settings.buffer}
              sync={settings.sync}
              legacy={legacyMode}
            />
          </div>
        );
      })}
    </div>
  );
}
export default render;
